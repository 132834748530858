import React from 'react';
import './ConnectionItemStyle.css';
import {IconPhone, IconMail} from '@tabler/icons-react'


export enum ConnectionType {
    Phone = 0,
    Email,
    Fax,
    Website
}

type ConnectionItemProps = {
  text: string;
  type: ConnectionType;
};

const ConnectionItem: React.FC<ConnectionItemProps> = ({ type, text }) => {
    const getConnection = () => {
        switch(type){
            case ConnectionType.Phone:
                return <PhoneConnectionItem phoneNumber={text}/>;
            case ConnectionType.Email:
                return <EmailConnectionItem emailAddress={text}/>;
        }
    };

    return (
        <div className="connection-item-container">
            {getConnection()}
        </div>
    );
};

export default ConnectionItem;

type PhoneConnectionItemProps = {
    phoneNumber: string;
  };

const PhoneConnectionItem: React.FC<PhoneConnectionItemProps> = ({ phoneNumber }) => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;    
    var formattedNumber = phoneNumber.replace(phoneRegex, '($1) $2-$3');
    return (
      <div className="connection-item-container">
        <a className="connection-item-text" href={"tel:" + phoneNumber}>{formattedNumber}</a>        
        <IconPhone size={38}/>
      </div>
    );
};

type EmailConnectionItemProps = {
    emailAddress: string;
  };

const EmailConnectionItem: React.FC<EmailConnectionItemProps> = ({ emailAddress }) => {
    return (
      <div className="connection-item-container">
        <a className="connection-item-text" href="mailto:sbell1@drhorton.com">{emailAddress}</a>        
        <IconMail size={38}/>
      </div>
    );
};