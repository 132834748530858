import React from 'react';
import './DescriptionItemStyle.css';

type DescriptionItemProps = {
  text: string;
  icon: any;
};

const DescriptionItem: React.FC<DescriptionItemProps> = ({ text, icon }) => {
  return (
    <div className="description-item-container">
      <p>{text}</p>
      <div className='iconContainer'>{icon}</div>
    </div>
  );
};

export default DescriptionItem;
