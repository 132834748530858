import './App.css';
import DescriptionItem from './DescriptionItem';
import { IconMapPinFilled } from '@tabler/icons-react';
import { IconHeartHandshake } from '@tabler/icons-react';
import { IconArrowWaveRightUp } from '@tabler/icons-react';
import { IconShield } from '@tabler/icons-react';
import ConnectionItem, { ConnectionType } from './ConnectionItem';

function App() {
  return (
    <div className="App mainBody">
      <div className='innerContainer mainImageContainer'>
        <div className='upperContainer'>
          <div className='titleContainer'>
              <div className="title">Shelli Bell</div>
          </div>
          <div className="subtitleContainer"><div className='subtitle'>Experienced Gulf Coast Realtor</div></div>
        </div>
        <div className='lowerContainer lowerupper'>
          <div className='descriptionItemsContainer'>            
            <DescriptionItem icon={<IconMapPinFilled size={48}/>} text='Local Expertise'/>            
            <DescriptionItem icon={<IconHeartHandshake size={48}/>} text='Personalized Service'/>
            <DescriptionItem icon={<IconArrowWaveRightUp size={48}/>} text='Seamless Experience'/>
            <DescriptionItem icon={<IconShield size={48}/>} text='Dedicated Advocacy'/>
          </div>
        </div>
        <div className='lowerContainer lowerlower'>
          <div className="drHortonContainer">
            <div className='drTextContainer'>
              <div className='bodyfont'>Shelli is proudly collaborating with America's top homebuilder, DR Horton, where she brings unparalleled expertise and dedication to make your dream home a reality.</div>
            </div>
            <div className='drhLogoContainer' onClick={() => window.location.href='https://www.drhorton.com/'}>
              <img className='drhLogo' src={require('./DRH-logo.png')} alt="whatever" />
            </div>
          </div>
          <div className='divider'></div>
          <div className='contactContainer'>
            <div className='contactInfoCard'>
              <ConnectionItem text='251-680-7848' type={ConnectionType.Phone}/>
              <ConnectionItem text='sbell1@DRHorton.com' type={ConnectionType.Email}/>
            </div>
            <div className='cta'>Don’t wait – connect with Shelli today and take the first step towards a future in a home you'll love.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
